/* Timeline */

.timeline {
	list-style: none;
	padding: 0;
	position: relative;

	&:before {
		top: 0;
		bottom: 0;
		position: absolute;
		content: " ";
		width: 1px;
		background-color: #D5D5D5;
		left: 2%;
		margin-left: -1.5px;
	}

	.timeline-wrapper {
		display: block;
		margin-bottom: 20px;
		position: relative;
		width: 100%;
		// padding-right: 90px;

		&:before {
			content: " ";
			display: table;
		}

		&:after {
			content: " ";
			display: table;
			clear: both;
		}

		.timeline-panel {
			// border-radius: 4px;
			// padding: 20px;
			// position: relative;
			// background: $white;
			// box-shadow: 0px 1px 2px 0px #00000040;

			margin-left: 4%;

			// &:before {
			// 	position: absolute;
			// 	top: 0;
			// 	width: 100%;
			// 	height: 2px;
			// 	content: "";
			// 	left: 0;
			// 	right: 0;
			// }
			// &:after {
			// 	position: absolute;
			// 	top: 10px;
			// 	right: -14px;
			// 	display: inline-block;
			// 	border-top: 14px solid transparent;
			// 	border-left: 14px solid $white;
			// 	border-right: 0 solid $white;
			// 	border-bottom: 14px solid transparent;
			// 	content: " ";
			// }
			.timeline-inbox {
				border-radius: 4px;
				padding: 20px;
				position: relative;
				background: $white;
				box-shadow: 0px 1px 2px 0px #00000040;

				.time {
					color: #929292;
					font-size: 14px;
				}

				.badge {
					padding: 6px 16px 6px 16px !important;
					border-radius: 50px;
					font-weight: 600;
					font-size: 13px !important;
				}

				.obsbadge {
					color: #EF4444;
					background-color: #EF444436;

				}
				.eptwbadge{
					color: #FBBF24;
					background-color: #FBBF2436;
				}
				.incidentbadge{
					color: #60a5fa;
					background-color: #60a5fa36;
				}
				.documentbadge{
					color: #a78bfa;
					background-color: #a78bfa36;
				}
				.riskbadge{
					color: #f472b6;
					background-color: #f472b636;
				}
				
			}

			.timeline-title {
				margin-top: 0;
				color: $dark;
				text-transform: uppercase;
			}

			.timeline-body {
				p {
					&+p {
						margin-top: 5px;
					}
				}

				ul {
					margin-bottom: 0;
				}
			}

			.timeline-footer {
				span {
					font-size: .6875rem;
				}

				i {
					font-size: 1.5rem;
				}
			}
		}

		.timeline-badge {
			width: 10px;
			height: 10px;
			position: absolute;
			top: 5px;
			left: calc(2% - 5px);
			z-index: 10;
			border-radius: 50%;


			background: #887A7A;

			i {
				color: $white;
			}
		}

		&.timeline-inverted {
			padding-right: 0;
			padding-left: 90px;

			.timeline-panel {
				margin-left: auto;
				margin-right: 15%;

				&:after {
					border-left-width: 0;
					border-right-width: 14px;
					left: -14px;
					right: auto;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.timeline {
		.timeline-wrapper {
			padding-right: 150px;

			&.timeline-inverted {
				padding-left: 150px;
			}

			.timeline-panel {
				width: 60%;
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}

@media (max-width: 576px) {
	.timeline {
		.timeline-wrapper {
			.timeline-panel {
				width: 68%;
			}
		}
	}
}

@each $color, $value in $theme-colors {
	.timeline-wrapper-#{$color} {
		.timeline-panel {
			&:before {
				background: theme-color($color);
			}
		}

		.timeline-badge {
			background: theme-color($color);
		}
	}
}